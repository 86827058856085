import $ from 'jquery';

//import initDatatables from './isubdb/modules/tables/datatable';
import 'select2';


$(function() {
    
 
});

